* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  /* background-color: #000000; */
  /* color: white; */
}

/* pricing section  */
.pricing-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  background-color: #fff;
  color: #000;
  padding: 0 16px;
  padding-top: 16px;
  padding-bottom: 48px;
}

.pricing-heading {
  text-align: center;
  font-size: 72px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -5px;
  margin-top: 24px;
}

.pricing-section .pricing-description {
  margin-top: 24px;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  max-width: 55ch;
}

h2 {
  margin-bottom: 0px;
}

.pricing-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: #fff;
  padding: 40px 80px;
  gap: 48px;
  margin-top: 24px;
}

.pricing-section .pricing-card-container .pricing-card:first-child,
.pricing-section .pricing-card-container .pricing-card:nth-child(3) {
  padding-top: 44px;
}

.pricing-section .pricing-card-container .pricing-card {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  background-color: #121212;
  border: 1px solid #393939;
  border-radius: 16px;
}

.pricing-section .pricing-card-container .pricing-card .pricing-card-heading {
  font-size: 24px;
  font-weight: 400;
}

h3 {
  margin: 0;
}

.pricing-section .pricing-card-container .pricing-card .pricing-card-description {
  font-size: 16px;
  font-weight: 300;
  color: #999;
  margin-top: 8px;
}

p {
  margin: 0;
}

.pricing-section .pricing-card-container .pricing-card .pricing-card-price {
  font-size: 36px;
  font-weight: 500;
}

.pricing-section .pricing-card-container .pricing-card .divider {
  height: 1.5px;
  margin-top: 8px;
  margin-bottom: 16px;
  width: 100%;
  background: radial-gradient(69.21% 131.55% at 52.19% -68.45%, #fff 0, rgba(57, 57, 57, 0) 100%);
}

.pricing-section .pricing-card-container .pricing-card .pricing-card-feature-list {
  font-size: 14px;
  font-weight: 300;
  color: #ccc;
  margin-bottom: auto;
}

.pricing-section .pricing-card-container .pricing-card .pricing-card-feature-list li {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.pricing-section .pricing-card-container .pricing-card .divider-b {
  height: 1.5px;
  margin-top: 8px;
  margin-bottom: 24px;
  width: 100%;
  background: radial-gradient(69.21% 131.55% at 52.19% -68.45%, #fff 0, rgba(57, 57, 57, 0) 100%);
}

a {
  color: inherit;
  text-decoration: inherit;
}

ul {
  color: #7D7D7D;
  list-style: none;
  margin: 0;
  padding: 0;
}

.pricing-section .pricing-card-container .pricing-card .pricing-card-button {
  width: 216px;
  padding: 10px 36px;
  border: 1px solid #555;
  background-color: #393939;
  font-size: 14px;
  border-radius: 12px;
  cursor: pointer;
}

/* Pricing-card 2  */
.pricing-section .pricing-card-container .pricing-card:nth-child(2) {
  margin-bottom: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 89, 194, .549);
}

.pricing-section .pricing-card-container .pricing-card .pricing-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  margin-bottom: 8px;
}

.pricing-section .pricing-card-container .pricing-card .pricing-tag .offer {
  font-weight: 500;
  color: #006be9;
  background-color: #cee5ff;
  padding: 4px;
  border-radius: 4px;
}

.pricing-section .pricing-card-container .pricing-card .pricing-tag .offer-text {
  font-weight: 500;
  text-transform: uppercase;
  color: #000;
  background-color: #fff;
  padding: 4px;
  border-radius: 4px;
}

.pricing-section .pricing-card-container .pricing-card .pricing-card-heading {
  font-size: 24px;
  font-weight: 400;
}

.card2-span {
  font-size: 18px;
  font-weight: 400;
  color: rgb(153, 153, 153);
  margin-right: 8px;
}

.pricing-section .pricing-card-container .pricing-card:nth-child(2) .pricing-card-button {
  box-shadow: 0 0 18px 0 rgba(39, 132, 241, .541);
}

button.pricing-card-button {
  color: #F5F5F5;
}