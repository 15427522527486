*{
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  /* background-color: #000000; */
  /* color: white; */
}

/* Global section  */
.stats-section {
  display: flex;
  color: #fff;
  justify-content: space-around;
  padding: 100px 48px;
  background: url(https://beta.dayzero.vercel.app/_next/static/media/stats-bg.916429f3.png);
}

.stats-section .left-side h3 {
  font-size: 72px;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: -5px;
}

.stats-section .stats-data-grid .item {
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.stats-section .stats-data-grid .item .count {
  font-size: 44px;
  font-weight: 600;
}

.stats-section .left-side {
  width: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats-section .stats-data-grid {
  box-shadow: 0 0 60px 0 rgba(181,199,220,.2);
  display: grid;
  background: #161616;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2,1fr);
  padding: 10px;
  gap: 20px;
  border: 1px solid #393939;
}







