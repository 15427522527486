body {
  font-family: 'Inter', sans-serif;
}

.first-faq {
  background-color: #000;
}

.div-top {
  /* background-color: #000; */
  border-radius: 1px solid #222;
  height: 900px;
  width: 65%;
  margin: auto;
  align-items: center;
}

@media screen and (max-width:420px) {
  .div-top {
    width: auto;
  }
}

.top-page {
  margin: 0px;
  /* height: 100vh; */
}

.faq {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  /* background: #121212;
   */
}

.faq-item .header {
  color: white;
  display: flex;
  padding-left: 30px;
  padding-right: 20px;
  font-size: 20px;
  align-items: center;
  margin: 20px;
  height: 60px;
  /* width: 90%; */
  background: #121212;
  /* background-color: white; */
  border: 1px solid #222;
  border-radius: 8px;
  justify-content: space-between;
}

.faq-item h3 {
  font-size: 16px;
  align-items: center;
}

.on-click {
  display: flex;
  flex-direction: column;
}

.faq-item button {
  color: white;
  background-color: #121212;
  border: none;
  cursor: pointer;
}

.faq-item p {
  background-color: #121212;
  color: #968a8a;
  padding: 15px;
  font-size: 14px;
  border: 1px solid #222;
  border-radius: 8px;
  margin: 20px;
}

/* About section  */
.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  background-color: #fff;
  color: #000;
  padding: 60px 10px;
}

.about-section .about-title {
  text-align: center;
  font-size: 72px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -5px;
}

.about-section .about-description {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  width: 65vw;
}

a {
  color: #000;
}

.about-section .login-btn {
  margin-top: 40px;
  padding: 10px 80px;
  border: 1px solid #555;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
}

.login-btn:hover {
  background: Black;
  color: #fff;
}





/* .buttom {
  gap: 40px;
  margin-top: 10px;
  margin-bottom: 40px;
}
.buttom .btn {
  all: unset;
  padding: 6px 16px;
  height: 32px;
  border: 1px solid black;
  color: #0a0a0a;
  font-size: 16px;
  font-weight: 400;
  width: 100px;
  align-items: center;
}

.buttom .btn:hover {
  background: Black;
  color: #fff;
} */