/* footer css here */
body {
  font-family: 'inter' sans-serif;
}

.footer-section {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 120px 60px;
  color: rgb(255, 255, 255);
  background-color: black;
}

.cardss {
  /* display: flex; */
  background-color: #000;
  /* flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; */
  gap: 12px;
}

/*   
  .card-2 {
    grid-column: 1 / span 2;
    width: 30ch;
  } */

.footer-section .card .logo {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 8px;
}

.footer-section .card .logo h2 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.footer-section .card h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.footer-section .card p {
  font-size: 14px;
  font-weight: 400;
}

.footer-section ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-section .card ul li {
  font-size: 14px;
  margin-bottom: 8px;
  color: grey;
}

.footer-section ul li:hover {
  color: white;
}

a {
  color: gray;
}

a:hover {
  color: white;
}

/* .footer-nav-link {
    color: inherit;
    text-decoration: none;
  } */

.footer-section .card .follow_links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

h3 {
  color: white;
}