
body {
  background-color:black;
}
.h1-text{
  text-align: center;
  font-size: 72px;
  font-weight: 600;
  letter-spacing: -5px;
  line-height: 1;
  color:white;
}
.para{
  font-size: 16px;
  font-weight: 200;
  color: #9999;
  text-align: center;
  
}
.mycard{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dark-1);
  border: 1px solid var(--dark-3);
  border-radius: 12px;
  padding: 28px 20px;
  box-shadow: 0 0 50px 0 rgba(181,199,220,.2);
}
.img-fluid{
  width:100%;
  height:100%;
}
.h3-text{
  color:white;
  font-size: 20px;
  font-weight: 500;
}
.card-para{
  font-size: 14px;
  font-weight: 300;
  color: #999;
}

